<template>
    <assets-summary-skeleton v-if="loading" />
    <error v-else-if="error" />
    <template v-else v-for="(asset, idx) in assets" :key="idx">
      <div class="card mb-2">
        <div class="card-body py-2">
          <div class="d-flex">
            <div
              class="
                flex-shrink-0
                align-self-center
                d-none d-xxl-inline-flex
                me-1
              "
            >
              <asset-details-link
                class="flex-shrink-0 d-flex mx-1 bg-body-secondary rounded"
                style="height: 36px; width: 36px"
                :type="asset.asset_type"
                :code="asset.asset_code"
                :issuer="asset.asset_issuer"
              >
                <img
                  v-if="asset.asset_code === 'XLM'"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="$t('operation_types.asset_icon')"
                  v-tooltip
                  :src="xlmLogo"
                  class="px-1 align-self-center mx-auto img-fluid"
                />
                <img
                  v-else-if="asset?.image"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="$t('operation_types.asset_icon')"
                  v-tooltip
                  :src="asset?.image"
                  class="px-1 align-self-center mx-auto img-fluid"
                />
                <i
                  v-else
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="
                    $t('operation_types.asset_icon_not_available')
                  "
                  v-tooltip
                  class="
                    bi bi-circle-fill
                    fs-5
                    align-self-center
                    mx-auto
                    text-muted
                  "
                ></i>
              </asset-details-link>
            </div>
            <div class="flex-grow-1">
              <div class="row g-2">
                <div class="col-xxl-3 align-items-center d-flex">
                  <div class="flex-grow-1 ms-1">
                    <div
                      class="
                        fw-bold
                        font-monospace
                        small
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      <asset-details-link
                        :type="asset.asset_type"
                        :code="asset.asset_code"
                        :issuer="asset.asset_issuer"
                        class="text-decoration-none text-body"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :data-bs-original-title="$t('assets_summary.asset_name')"
                        v-tooltip
                        >{{
                          asset.asset_type === "native"
                            ? $t("assets_summary.xlm")
                            : asset.asset_code
                        }}</asset-details-link
                      >
                    </div>
                    <div
                      class="
                        font-monospace
                        small
                        d-flex
                        align-items-center
                        justify-content-between
                      "
                    >
                      <asset-details-link
                        v-if="asset.asset_code === 'XLM'"
                        :type="asset.asset_type"
                        :code="asset.asset_code"
                        :issuer="asset.asset_issuer"
                        class="text-muted text-decoration-none"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :data-bs-original-title="
                          $t('assets_summary.asset_issuer')
                        "
                        v-tooltip
                      >
                        {{ $t("assets_summary.native") }}</asset-details-link
                      >
                      <asset-details-link
                        v-else
                        :type="asset.asset_type"
                        :code="asset.asset_code"
                        :issuer="asset.asset_issuer"
                        class="text-muted text-decoration-none"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :data-bs-original-title="
                          $t('assets_summary.asset_issuer')
                        "
                        v-tooltip
                        v-middle-ellipsis="{
                          size: 6,
                          text: asset.asset_issuer,
                        }"
                      >
                      </asset-details-link>
                    </div>
                  </div>
                  <div v-if="asset.verified" class="flex-shrink-0 me-2">
                    <i
                      class="bi bi-patch-check-fill text-primary fs-5"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('assets_summary.verified_asset')
                      "
                      v-tooltip
                    ></i>
                  </div>
                </div>
                <div class="col-xxl-3 d-flex align-items-center rounded">
                  <div class="flex-grow-1 ms-2">
                    <div
                      class="
                        fw-bold
                        font-monospace
                        small
                        d-flex
                        align-items-center
                        justify-content-between
                        me-2
                      "
                    >
                      <span
                        class="text-decoration-none"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :data-bs-original-title="
                          $t('assets_summary.statistics_about_asset')
                        "
                        v-tooltip
                      >
                        {{ $t("assets_summary.stats") }}</span
                      >
                      <span
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :data-bs-original-title="$t('assets_summary.market_cap')"
                        v-tooltip
                        >{{
                          asset.market_cap
                            ? parseFloat(asset.market_cap).toLocaleString()
                            : "-"
                        }}
                      </span>
                    </div>
                    <div
                      class="
                        font-monospace
                        small
                        d-flex
                        align-items-center
                        justify-content-between
                        me-2
                      "
                    >
                      <span
                        class="text-muted text-decoration-none"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :data-bs-original-title="$t('assets_summary.payments')"
                        v-tooltip
                      >
                        {{
                          asset.payments
                            ? parseFloat(asset.payments).toLocaleString()
                            : "-"
                        }}
                      </span>
                      <span
                        class="small text-muted text-decoration-none"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title=""
                        :data-bs-original-title="$t('assets_summary.trustlines')"
                        v-tooltip
                        >{{
                          asset.trustlines
                            ? parseFloat(asset.trustlines).toLocaleString()
                            : "-"
                        }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-xxl-3 d-flex align-items-center">
                  <div class="flex-grow-1 ms-2">
                    <div
                      class="
                        fw-bold
                        font-monospace
                        small
                        d-flex
                        align-items-center
                        justify-content-between
                        me-2
                      "
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      :data-bs-original-title="$t('assets_summary.price')"
                      v-tooltip
                    >
                      <span class="text-decoration-none">
                        {{ $t("assets_summary.xlm") }}</span
                      >
                      <span
                        >{{
                          asset.price_xlm
                            ? "~ " + parseFloat(asset.price_xlm).toLocaleString()
                            : "-"
                        }}
                      </span>
                    </div>
                    <div
                      class="
                        font-monospace
                        small
                        d-flex
                        align-items-center
                        justify-content-between
                        me-2
                      "
                    >
                      <span class="text-muted text-decoration-none"> USD </span>

                      <span class="small text-muted text-decoration-none">{{
                        asset.price_usd
                          ? "~ " + parseFloat(asset.price_usd).toLocaleString()
                          : "-"
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    ms-xxl-auto
                    col-xxl-3
                    align-items-center
                    d-flex
                    rounded
                  "
                >
                  <div class="flex-shrink-0 mx-2">
                    <i class="bi bi-globe fs-4"></i>
                  </div>
                  <div
                    class="flex-grow-1 text-end small font-monospace"
                  >
                    <b
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      v-tooltip
                      :data-bs-original-title="
                        $t('assets_summary.issuer_website')
                      "
                      class="small"
                      ><div class="text-decoration-none">{{ asset.domain }}</div>
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="assets && assets.length == 0 && !loading">
      <no-content />
    </template>
</template>
<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import AssetsSummarySkeleton from "@/components/skeletons/summaries/AssetsSummarySkeleton.vue";
import AssetDetailsLink from "@/components/links/AssetDetailsLink.vue";
import Error from "@/components/Error.vue";
import NoContent from "@/components/NoContent.vue";

export default defineComponent({
  name: "AssetsSummary",
  components: {
    AssetsSummarySkeleton,
    AssetDetailsLink,
    Error,
    NoContent
  },
  setup() {
    const store = useStore();

    const assets = computed(() => store.getters["assets/getAssets"].data);
    const loading = computed(() => store.getters["assets/getAssets"].loading);
    const error = computed(() => store.getters["assets/getAssets"].error);

    const accountLabels = computed(() => store.getters["accounts/getLabels"]);

    console.log(assets.value.length)
    return {
      store,
      assets,
      loading,
      error,
      accountLabels,
      xlmLogo: require("@/assets/images/assets/xlm.png"),
    };
  },
});
</script>
