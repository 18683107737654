<template>
  <div class="d-block d-md-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
    <div class="ms-2 mt-2 mt-md-0">
      <div class="d-flex justify-content-end">
        <span class="d-inline-block">
          <button
            class="btn border d-flex align-items-center gap-1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ $t("assets_page.sort_by") }}
            <span class="">{{ sortOptions[sortBy] }}</span
            ><i class="bi bi-chevron-down"></i>
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item"
                href="#"
                @click="changeFilter('rating')"
                >{{ sortOptions["rating"] }}</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                href="#"
                @click="changeFilter('trustlines')"
                >{{ sortOptions["trustlines"] }}</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                href="#"
                @click="changeFilter('market_cap')"
                >{{ sortOptions["market_cap"] }}</a
              >
            </li>
          </ul>
        </span>
        <span
          class="d-inline-block ms-1"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('assets_page.previous_page')"
          v-tooltip
        >
          <button
            class="btn border"
            @click="prevPage()"
            :disabled="disablePrevBtn"
          >
            <i class="bi bi-arrow-left"></i></button
        ></span>
        <span
          class="d-inline-block ms-1"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          :data-bs-original-title="$t('assets_page.next_page')"
          v-tooltip
        >
          <button class="btn border" @click="nextPage()">
            <i class="bi bi-arrow-right"></i></button
        ></span>
      </div>
    </div>
  </div>
  <assets-summary />
  <div class="py-2 px-3 border rounded d-flex font-monospace my-2">
    <div class="flex-grow-1 small align-self-center text-muted">
      {{ $t("top_accounts.showing") }}<b>{{ assets?.from }}</b
      >{{ $t("top_accounts.to") }}<b>{{ assets?.to }}</b
      >{{ $t("top_accounts.of") }}<b>{{ assets?.total }}</b>
    </div>
    <div class="flex-grow-0">
      <select
        class="form-select form-select-sm text-muted"
        aria-label=".form-select-sm example"
        v-model="paginate"
        @change="onChangePagination()"
      >
        <option v-for="(option, idx) in options" :value="option" :key="idx">
          {{ option + $t("top_accounts.rows") }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import Search from "@/components/Search.vue";
import AssetsSummary from "@/components/summaries/AssetsSummary.vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "Assets",
  components: {
    Search,
    AssetsSummary,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const paginate = ref(25);
    const options = ref([25, 50, 100]);
    const sortBy = ref("rating");

    const sortOptions = {
      trustlines: t("assets_page.asset_holders"),
      market_cap: t("assets_page.market_cap"),
      rating: t("assets_page.rating"),
    };

    const changeFilter = (opt) => {
      sortBy.value = opt;
      store.dispatch("assets/fetchAssets", {
        page: 1,
        sort: sortBy.value,
        paginate: paginate.value,
      });
    };
    store.dispatch("assets/fetchAssets", {
      page: 1,
      sort: sortBy.value,
      paginate: paginate.value,
    });

    const onChangePagination = () => {
      store.dispatch("assets/fetchAssets", {
        page: 1,
        sort: sortBy.value,
        paginate: paginate.value,
      });
    };
    const assets = computed(() => store.getters["assets/getAssets"]);
    const nextPage = () => {
      store.dispatch("assets/fetchAssets", {
        page: assets.value.page + 1,
        paginate: paginate.value,
        sort: sortBy.value,
      });
    };
    const prevPage = () => {
      store.dispatch("assets/fetchAssets", {
        page: assets.value.page + 1,
        paginate: paginate.value,
        sort: sortBy.value,
      });
    };

    const disablePrevBtn = computed(() => assets.value.page === 1);
    const disableNextBtn = computed(
      () => assets.value.page === assets.value.lastPage
    );
    return {
      nextPage,
      prevPage,
      disablePrevBtn,
      disableNextBtn,
      sortBy,
      sortOptions,
      changeFilter,
      onChangePagination,
      options,
      paginate,
      assets,
    };
  },
});
</script>
